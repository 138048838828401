<template>
  <div v-show="showTheCode" id="print-sticker">
    <div style="font-weight: bold; text-align: center">
      <p style="font-size: 9px">{{ hospital_name }}</p>
    </div>
    <div style="font-weight: bold; margin-top: 5px">
      <div>
        <span style="font-size: 8px">
          {{ result.person_type.person_type_name }}:
        </span>
        <span style="font-size: 13px">{{
          result.social_name
            ? getFirstAndLastNamePatientOrVisitor(result.social_name)
            : getFirstAndLastNamePatientOrVisitor(result.name_person)
        }}</span>
      </div>
      <div>
        <span style="font-size: 8px"> ENTRADA:</span>
        <span style="font-size: 13px"> {{ entryDate }} </span>
      </div>
    </div>
    <div
      style="
        font-weight: bold;
        margin-top: 15px;
        margin-left: 2px;
        margin-bottom: 15x;
        border: 1px solid #000;
        display: flex;
        flex-direction: column;
        padding: 5px;
      "
    >
      <div style="margin-top: 3px">
        <span style="font-size: 8px"> PACIENTE:</span>
        <span style="font-size: 13px">
          {{
            getFirstAndLastNamePatientOrVisitor(
              result.hospital_patient[0].hospital_patient_name
            )
          }}
        </span>
      </div>

      <div style="margin-top: 3px">
        <span style="font-size: 8px"> UNIDADE: </span>
        <span style="font-size: 13px">
          {{ result.hospital_patient[0].functional_unity }}
        </span>
      </div>
      <div style="margin-top: 3px">
        <span style="font-size: 8px"> LEITO: </span>
        <span style="font-size: 13px">
          {{ result.hospital_patient[0].hospital_bed }}
        </span>
        <span style="font-size: 25px; display: flex; justify-content: center">
          {{ result.person_type.person_type_name }}
        </span>
      </div>
    </div>

    <div style="margin-top: 5px; margin-left: 2px; font-weight: bold">
      <span style="font-size: 8px"> ETIQUETA: </span>
      <span style="font-size: 13px">{{ result.sticker.sticker_number }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    result: {},
  },
  data() {
    return {
      entryDate: "",
      hospital_name: "",
    };
  },
  mounted() {
    this.dataAtualFormatada();
    this.hospital_name = this.$store.state.authUser.unity_name;
  },
  methods: {
    getFirstAndLastNamePatientOrVisitor(name) {
      let name_splited = name.split(" ");

      if (name_splited.length == 1) {
        return name_splited[0].toUpperCase();
      }

      return (
        name_splited[0].toUpperCase() +
        " " +
        name_splited[name_splited.length - 1].toUpperCase()
      );
    },
    dateFormatted(e) {
      new Date(e);
    },
    dataAtualFormatada() {
      var data = new Date(),
        dia = data.getDate().toString(),
        diaF = dia.length == 1 ? "0" + dia : dia,
        mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = mes.length == 1 ? "0" + mes : mes,
        anoF = data.getFullYear();
      this.entryDate = diaF + "/" + mesF + "/" + anoF;
    },
  },
};
</script>

<style>
</style>