<template>
  <div>
    <v-container>
      <div v-if="searchVisitor">
        <div>
          <TablePatient></TablePatient>
        </div>
        <div v-if="createVisitors == false">
          <SearchVisitors
            @search="search = $event"
            @resultVisitors="visitors = $event"
            @loading="loading = $event"
            @formVisitors="
              (createVisitors = true),
                (searchVisitor = $store.state.viewPatient),
                (visitorDisabled = $event)
            "
            :options="options"
          ></SearchVisitors>
          <v-row>
            <v-data-table
              :headers="headers"
              :items="visitors"
              :page="page"
              :items-per-page="10"
              :loading="loading"
              :options.sync="options"
              :footer-props="{
                'items-per-page-text': 'Pacientes por Página',
                'items-per-page-options': [10, 25, 50, 100, -1],
              }"
              class="elevation-1"
            >
              <template v-slot:item.add="{ item }">
                <v-icon
                  color="green"
                  small
                  title="Adicionar"
                  class="mr-2"
                  v-if="item.historicalMovementPerson.departure_date"
                  @click="addPatient(item)"
                >
                  mdi-plus
                </v-icon>
                <v-icon
                  title="Sem saída confirmada"
                  @click="teste(item)"
                  small
                  class="mr-2"
                  color="red"
                  v-if="!item.historicalMovementPerson.departure_date"
                >
                  mdi-lock
                </v-icon>
              </template>
            </v-data-table>
          </v-row>
        </div>
      </div>
      <div v-if="createVisitors">
        <h2>Cadastrar Visitante e Acompanhante</h2>
        <v-row>
          <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
        </v-row>
        <v-form @submit.prevent="create">
          <v-row>
            <v-col cols="12" md="6">
              <v-radio-group v-model="saveForm.id_person_type" row>
                <h4>Tipo de Permanência*:</h4>
                <div class="space"></div>
                <v-radio label="Visitante" value="1"></v-radio>
                <v-radio label="Acompanhante" value="2"></v-radio>
                <v-col>
                  <h4 style="color: black">
                    Visitante(s):
                    {{ $store.state.personTypeCount.qtyVisitor }}
                  </h4>

                  <h4 style="color: black">
                    Acompanhante(s):
                    {{ $store.state.personTypeCount.qtyEscort }}
                  </h4>
                  <h5
                    style="color: red"
                    v-if="$store.state.personTypeCount.limit"
                  >
                    Limite de visitante/acompanhante excedido.*
                  </h5>
                </v-col>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="saveForm.name_person"
                label="Nome*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="saveForm.social_name"
                label="Nome Social"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                :items="kinships"
                v-model="saveForm.id_kinship"
                item-value="id"
                item-text="kinship_name"
                label="Parentesco*"
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="saveForm.phone_number"
                label="Telefone*"
                v-mask="'(##) #####-####'"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6" md="3">
              <v-text-field
                v-model="saveForm.document_number"
                label="CPF*"
                v-mask="'###.###.###-##'"
                :disabled="visitorDisabled"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="3">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="saveForm.permanence"
                    type="date"
                    label="Data de entrada*"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    clearable
                    @click:clear="saveForm.permanence = ''"
                  ></v-text-field>
                </template>
                <v-date-picker
                  no-title
                  locale="pt-br"
                  v-model="saveForm.permanence"
                  @input="dateMenu = false"
                  @change="formatDate(saveForm.permanence)"
                  :min="dateToday"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="saveForm.id_person_type == 2" cols="6" md="3">
              <v-text-field
                v-model="saveForm.final_permanence"
                type="date"
                label="Data final de permanência*"
                persistent-hint
                prepend-icon="mdi-calendar"
                clearable
                @click:clear="saveForm.final_permanence = ''"
              ></v-text-field>
            </v-col>
              <v-col cols="6" md="4">
              <v-text-field
                v-model="saveForm.observation"
                label="Observação"
                required
              ></v-text-field>
              </v-col>
            <v-col cols="6" md="4">
              <v-text-field
                v-model="saveForm.vaccine_observation"
                label="Vacina*"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            v-if="$store.state.personTypeCount.save"
            style="margin-left: 10px"
            class="float-end primary"
            dark
            type="submit"
            ><v-icon size="22" class="icon">mdi-arrow-right</v-icon>
            Salvar
          </v-btn>
          <v-btn class="float-end dark" dark @click="backVisitor()"
            ><v-icon size="22" class="icon">mdi-arrow-left</v-icon>
            Voltar
          </v-btn>
        </v-form>
      </div>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <AlertError
            class="mb-5"
            v-model="displayError"
            :resource="displayError"
          />
        </v-col>
      </v-row>
    </v-container>
    <Sticker ref="date" :result="resultPerson"></Sticker>
  </div>
</template>

<script>
import AlertSuccess from "../../alerts/Success-component.vue";
import AlertError from "../../alerts/Error-component.vue";
import SearchVisitors from "./SearchVisitors.vue";
import TablePatient from "../patient/TablePatient-comp.vue";
import Sticker from "../../sticker/Sticker.vue";
import axios from "@/axios";
export default {
  data() {
    return {
      options: {},
      loading: false,
      showTheCode: false,
      dateToday: "",
      resultPerson: "",
      selectObservation: "",
      selectObservationOthersText: false,
      saveForm: {
        //Person - Visitante e acompanhante
        name_person: "",
        vaccine_observation: "",
        social_name: "",
        observation: "",
        id_person_type: "",
        id_kinship: "",
        permanence: "",
        final_permanence: "",
        phone_number: "",
        document_number: "",
        name_type_document: "CPF",
        //Paciente
        hospital_patient_name: "",
        functional_unity: "",
        hospital_bed: "",
        attendance_date: "",
        attendance_number: "",
      },
      searchVisitor: true,
      createVisitors: false,
      dateMenu: false,
      date: "",
      search: {},
      visitorDisabled: true,
      visitors: [],
      permanence: "",
      kinship_name: "",
      resultVisitors: {},
      kinships: [],
      headers: [
        { text: "Permanência", value: "historicalMovementPerson.permanence" },
        { text: "Nome", value: "name_person" },
        { text: "Nome Social", value: "social_name" },
        { text: "CPF", value: "documents.document_number" },
        { text: "Paciente", value: "hospitalPatient[0].hospital_patient_name" },
        { text: "Parentesco", value: "kinship.kinship_name" },
        { text: "Adicionar", value: "add" },
      ],
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },
  components: {
    AlertError,
    AlertSuccess,
    SearchVisitors,
    TablePatient,
    Sticker,
  },
  created() {
    var date = new Date();

    var dia = String(date.getDate()).padStart(2, "0");

    var mes = String(date.getMonth() + 1).padStart(2, "0");

    var ano = date.getFullYear();

    this.dateToday = ano + "-" + mes + "-" + dia;

    axios.get("kinship").then((response) => {
      this.kinships = response.data;
    });
  },
  watch: {
  },
  methods: {
    create() {
      let today = new Date().toISOString().slice(0, 10);
      let currentDate = new Date(today);
      let searchDate = new Date(this.saveForm.permanence);

      if (!(searchDate >= currentDate)) {
        this.$swal({
          icon: "error",
          title:
            "Data de entrada precisa ser maior ou igual a data atual.",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }
      if (
        !(this.saveForm.final_permanence >= this.saveForm.permanence) &&
        this.saveForm.id_person_type == 2
      ) {
        this.$swal({
          icon: "error",
          title:
            "Data de saída precisa ser maior ou igual a Data de entrada.",
          showConfirmButton: false,
          timer: 4500,
        });
        return;
      }

      this.formAghuse();
      if (
        this.selectObservation == "Outros" ||
        this.selectObservation == "Restrição familiar"
      ) {
        this.saveForm.observation =
          this.selectObservation + ": " + this.saveForm.observation;
      } else {
        this.saveForm.observation = this.selectObservation;
      }
      axios
        .post("/person", this.saveForm)
        .then((response) => {
          //this.$refs.date.dateFormatted(response.data.created_at);
          this.resultPerson = response.data;
          this.$swal({
            icon: "success",
            title: "Cadastro realizado com sucesso.",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this.$htmlToPaper("print-sticker");
            this.backVisitor();
          });
        })
        .catch((error) => {
          if (error.detailsDeparture) {
            this.$swal({
              icon: "error",
              title: error.detailsDeparture,
              showConfirmButton: false,
              timer: 5000,
            });
          } else {
            var errors = "";
            for (var e in error) {
              if (error[e][0] !== "CPF inválido.") {
                errors += `${error[e][0]}, `;
              } else {
                errors += error[e][0];
              }
            }
            this.messages(errors);
          }
        });
    },
    backVisitor() {
      (this.createVisitors = false),
        (this.searchVisitor = true),
        (this.visitorDisabled = true),
        this.clearForm();
    },
    clearForm() {
      for (var value in this.saveForm) {
        if (value !== "permanence") {
          this.saveForm[value] = "";
        }
      }
      this.date = "";
    },
    formAghuse() {
      this.saveForm.hospital_patient_name =
        this.$store.state.aghusePatient.nome_paciente;
      this.saveForm.functional_unity =
        this.$store.state.aghusePatient.descricao_unidade_funcional;
      this.saveForm.hospital_bed = this.$store.state.aghusePatient.id_leito;
      this.saveForm.attendance_date =
        this.$store.state.aghusePatient.data_hora_internacao;
      this.saveForm.attendance_number =
        this.$store.state.aghusePatient.codigo_do_paciente;
    },
    formatDate(date) {
      this.date = date.split("-").reverse().join("/");
    },
    addPatient(item) {
      //criar um objeto para patient
      this.saveForm = {
        name_person: item.name_person,
        social_name: item.social_name,
        observation: item.observation,
        id_person_type: item.id_person_type,
        id_kinship: item.kinship.id_kinship,
        permanence: item.historicalMovementPerson.permanence,
        phone_number: item.phones.phone_number,
        document_number: item.documents.document_number,
        name_type_document: "CPF",
      };
      //paciente
      this.formAghuse();
      // Conversão do date-picker
      this.saveForm.permanence = this.saveForm.permanence
        .substring(0, 10)
        .split("/")
        .reverse()
        .join("-");
      this.date = this.saveForm.permanence;
      this.date = this.date.split("-").reverse().join("/");
      this.createVisitors = true;
    },
    messages(errors) {
      if (errors == "CPF inválido.") {
        this.$swal({
          icon: "error",
          title: errors,
          showConfirmButton: false,
          timer: 1000,
        });
      } else if (errors.includes("CPF inválido.")) {
        this.$swal({
          icon: "error",
          title: "CPF inválido.",
          showConfirmButton: false,
          timer: 1000,
        });
        setTimeout(() => {
          errors = errors.replace("CPF inválido.", "");
          this.$swal({
            icon: "error",
            title: `${errors} são campos obrigatórios.`,
            showConfirmButton: false,
            timer: 4000,
          });
        }, 1500);
      } else {
        this.$swal({
          icon: "error",
          title: `${errors} são campos obrigatórios.`,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>

<style>
.swal2-title {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
.v-data-table {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.space {
  padding: 10px;
}
</style>