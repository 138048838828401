<template>
  <div>
    <v-form @submit.prevent="searching">
      <v-container>
        <v-card-title>
          <span><h4>Pesquisar Visitante ou Acompanhante</h4></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="CPF"
                v-mask="'###.###.###-##'"
                maxlength="14"
                v-model="search.document_number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <v-text-field
                label="Nome"
                maxlength="255"
                v-model="search.visitorsName"
                hint=""
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-menu
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
            </v-menu>
          </v-row>
        </v-card-text>

        <div class="button_actions">
          <v-btn
            elevation="0"
            color="dark"
            dark
            @click="redirectPatient"
            style="margin-left: 2rem"
            ><v-icon size="22" class="icon">mdi-keyboard-backspace</v-icon>
            Voltar
          </v-btn>
          <v-card-actions style="margin-right: 2rem">
            <v-spacer></v-spacer>
            <v-btn
              @click="btlimpar"
              class="float-end primary"
              style="margin-left: 10px"
            >
              <v-icon size="22" class="icon">mdi-broom</v-icon>
              <span>Limpar</span>
            </v-btn>
            <v-btn elevation="0" color="success" dark type="submit"
              ><v-icon size="22" class="icon">mdi-magnify-plus-outline</v-icon>
              Pesquisar
            </v-btn>
            <!-- <v-btn
            v-if="newVisitors"
            class="float-end primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="$emit('formVisitors')"
            ><v-icon size="22" class="icon">mdi-plus</v-icon>
            Novo
          </v-btn> -->
          </v-card-actions>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  props: {
    options: {},
  },
  data() {
    return {
      dateMenu: false,
      search: {
        document_number: "",
        visitorsName: "",
      },
      visitors: "",
      //Campo date
      date: "",
    };
  },
  created() {
    this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10);
    this.search.attendance_date = this.date;
    this.date = this.date.split("-").reverse().join("/");
    this.options = {};
    this.searching();
  },
  methods: {
    searching() {
      this.$emit("resultVisitors", []);
      this.$emit("loading", true);
      var data = new Object();
      data.document_number = this.search.document_number;
      data.name = this.search.visitorsName;
      axios
        .post(
          `person/search-person-departure-null?per_page=${
            this.options.itemsPerPage ? this.options.itemsPerPage : ""
          }`,
          data
        )
        .then((response) => {
          this.$emit("resultVisitors", response.data.data);

          if (Object.values(response.data.data).length == 0) {
            this.$swal({
              icon: "error",
              title:
                "Nenhum Visitante/Acompanhante Encontrado. \nCadastre um novo!",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              (this.$store.state.personTypeCount = {
                qtyVisitor: "",
                qtyEscort: "",
                save: false,
                limit: false,
              }),
                axios
                  .post("person/return-person-type-count", {
                    personType: this.$store.state.aghusePatient.nome_paciente,
                  })
                  .then((e) => {
                    this.$store.state.personTypeCount = {
                      qtyVisitor: e.data.qtyVisitor,
                      qtyEscort: e.data.qtyEscort,
                      save: e.data.save,
                      limit: e.data.limit
                    };
                  });
              this.$store.state.viewPatient = true;
              this.$emit("formVisitors", false);
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            title: "Ocorreu um erro na pesquisa.",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this.$emit("formVisitors", true);
          });
        })
        .finally(() => {
          this.$emit("loading", false);
        });
    },
    btlimpar() {
      this.search.document_number = "";
      this.search.visitorsName = "";
    },
    redirectPatient() {
      this.$store.state.stepperPatient = true;
      this.$store.state.stepperVisitors = false;
    },
    formatDate(date) {
      this.date = date.split("-").reverse().join("/");
    },
  },
};
</script>

<style scoped>
.swal2-title {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
.button_actions {
  display: flex;

  flex-direction: row;

  justify-content: space-between;

  align-items: center;

  flex-wrap: wrap;
}
</style>