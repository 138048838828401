<template>
  <div>
    <div v-if="$store.state.stepperPatient">
      <v-container>
        <v-card-title>
          <span><h4>Pesquisar Paciente</h4></span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="searching">
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <v-text-field
                  label="Nome"
                  v-model="search.hospital_patient_name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Unidade Funcional"
                  v-model="search.functional_unity"
                  hint=""
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Leito"
                  hint=""
                  v-model="search.hospital_bed"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  type="date"
                  v-model="search.attendance_date"
                  label="Data Inicio"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="btlimpar"
                class="float-end primary"
                style="margin-left: 10px"
              >
                <v-icon size="22" class="icon">mdi-broom</v-icon>
                <span>Limpar</span>
              </v-btn>
              <v-btn elevation="0" color="success" dark type="submit"
                ><v-icon size="22" class="icon"
                  >mdi-magnify-plus-outline</v-icon
                >
                Pesquisar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-container>

      <v-container>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="hospitalPatient"
            :loading="loading"
            :options.sync="options"
            :items-per-page="10"
            :footer-props="{
              'items-per-page-text': 'Pacientes por Página',
              'items-per-page-options': [10, 25, 50, 100, -1],
            }"
            class="elevation-1"
          >
            <template v-slot:item.add="{ item }">
              <v-icon
                color="green"
                small
                class="mr-2"
                @click="addPatient(item)"
              >
                mdi-plus
              </v-icon>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </div>
    <div v-if="$store.state.stepperVisitors">
      <TableVisitors></TableVisitors>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import TableVisitors from "../visitors/TableVisitors-comp.vue";
import config from "../../../config/config";
export default {
  data() {
    return {
      next: false,
      loading: false,
      hospitalPatient: [],
      patient: [],
      headers: [
        { text: "Atendimento", value: "id_paciente" },
        { text: "Paciente", value: "nome_paciente" },
        { text: "Data de Internação", value: "data_hora_internacao" },
        { text: "Unidade Funcional", value: "descricao_unidade_funcional" },
        { text: "Leito", value: "id_leito" },
        { text: "Nome da Mãe", value: "nome_mae" },
        { text: "Adicionar", value: "add" },
      ],
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },
      dateMenu: false,
      search: {
        hospital_patient_name: "",
        hospital_bed: "",
        functional_unity: "",
        attendance_date: "",
        per_page: "",
      },
      options: {},
      //Campo date
      date: "",
    };
  },
  components: {
    TableVisitors,
  },
  created() {    
    this.date = this.date.split("-").reverse().join("/");
    this.searching();
  },
  methods: {
    searching() {
      this.hospitalPatient = [];
      this.loading = true;
      var data = new Object();
      data.nome = this.search.hospital_patient_name;
      data.unf_descricao = this.search.functional_unity;
      data.lto_lto_id = this.search.hospital_bed;
      data.nome_empresa = this.$store.state.authUser.unity_name;
      data.dthr_internacao = this.search.attendance_date
        .split("-")
        .reverse()
        .join("/");
      axios
        .post(`${config.apiAGHUSE}`, data)
        .then((response) => {
          this.hospitalPatient = response.data.data;
          if (response.data.data == 0) {
            this.$swal({
              icon: "error",
              title: "Nenhum Registro Encontrado!",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addPatient(item) {
      if (item.id_leito == null) {
        item.id_leito = "QUARTO";
      }
      this.$store.state.aghusePatient = item;
      this.$store.state.stepperPatient = false;
      this.$store.state.stepperVisitors = true;
      this.next = true;
    },
    formatDate(date) {
      this.date = date.split("-").reverse().join("/");
    },
    btlimpar() {
      this.search.hospital_patient_name = "";
      this.search.functional_unity = "";
      this.search.hospital_bed = "";
      this.search.attendance_date = "";
      this.date = "";
    },
  },
};
</script>

<style>
.v-data-table {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>