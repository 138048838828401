<template>
  <div>
    <div v-if="stepperPatient">
      <v-container>
        <h2>Paciente</h2>
        <v-row>
          <AlertError
            class="mb-5"
            v-model="displayError"
            :resource="displayError"
          />
        </v-row>
        <v-row>
          <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="$store.state.aghusePatient.nome_paciente"
              :rules="nameRules"
              label="Nome"
              disabled
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1">
            <v-text-field
              v-model="$store.state.aghusePatient.data_nascimento"
              :rules="nameRules"
              label="Idade"
              disabled
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="5">
            <v-text-field
              label="Unidade Funcional"
              v-model="$store.state.aghusePatient.descricao_unidade_funcional"
              disabled
              hint=""
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="3">
            <v-text-field
              v-model="$store.state.aghusePatient.id_leito"
              label="Leito"
              disabled
              required
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field
              v-model="$store.state.aghusePatient.data_hora_internacao"
              label="Data de Internação"
              disabled
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="stepperVisitors">
      <TableVisitors
        @stepperPatient="stepperPatient = $event"
        @stepperVisitors="stepperVisitors = $event"
      ></TableVisitors>
    </div>
  </div>
</template>
<script>
import AlertSuccess from "../../alerts/Success-component.vue";
import AlertError from "../../alerts/Error-component.vue";
import TableVisitors from "../visitors/TableVisitors-comp.vue";
export default {
  components: {
    AlertError,
    AlertSuccess,
    TableVisitors,
  },
  props: {
    patient: {},
  },
  data() {
    return {
      dialog: false,
      next: false,
      stepperPatient: true,
      stepperVisitors: false,
      search: {},
      hospitalPatient: [],
      displayError: {
        display: false,
        message: [],
      },
      displaySuccess: {
        display: false,
        message: [],
      },
      dialogEdit: false,
    };
  },
  methods: {},
};
</script>

<style>
.v-data-table {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
</style>